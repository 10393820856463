@import "variables";
@import "functions";

.landing_tips_content {
  @include media(mobile, tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
}

.landing_city_content {
  @include media(mobile, tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.landing_city_box {
  cursor: pointer;

  @include media(mobile, tablet) {
    margin-bottom: 32px;
  }

  &_image {
    -webkit-border-top-left-radius: 16px;
    -webkit-border-top-right-radius: 16px;
    -moz-border-radius-topleft: 16px;
    -moz-border-radius-topright: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &_title {
    font-size: $text-4;
    background-color: #F5F5F5;
    padding: 16px;
    -webkit-border-bottom-right-radius: 16px;
    -webkit-border-bottom-left-radius: 16px;
    -moz-border-radius-bottomright: 16px;
    -moz-border-radius-bottomleft: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.landing_title_section {
  font-size: $text-6;
}

.landing_path {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @include media(mobile, tablet) {
    margin-bottom: 32px;
  }
}

.landing_path_image {
  font-size: 64px;
  margin-bottom: 16px;
}

.landing_path_title {
  font-size: $text-3;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

.landing_path_subtitle {
  font-size: $text-2;
  text-align: center;
}

.landing_divisor_content {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 48px;
}

.landing_divisor {
  width: 128px;
  height: 4px;
  border-radius: 32px;
  background: $primary-light;
}