.atom-grid-rows-content-1 {
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 24px;
}

.atom-grid-rows-content-2 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    column-gap: 24px;
}

.atom-grid-rows-content-3 {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 24px;
}

.atom-grid-rows-content-4 {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    column-gap: 24px;
}

.atom-grid-rows-content-5 {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 24px;
}

.atom-grid-columns-content-1 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 24px;
}

.atom-grid-columns-content-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
}

.atom-grid-columns-content-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
}

.atom-grid-columns-content-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 24px;
}

.atom-grid-columns-content-5 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 24px;
}