/* Spacings - Base size: 16px */

$header-size: 64px;
$footer-size: 410px;

$spacer-0: 0; // 0px
$spacer-1: .25rem; // 4px
$spacer-2: .5rem; // 8px
$spacer-3: .75rem; // 12px
$spacer-4: 1rem; // 16px (base)
$spacer-5: 1.5rem; // 24px
$spacer-6: 2rem; // 32px
$spacer-7: 2.5rem; // 40px
$spacer-8: 3rem; // 48px
$spacer-9: 4rem; // 64px

$spacers: (
  "spacer-0": $spacer-0,
  "spacer-1": $spacer-1,
  "spacer-2": $spacer-2,
  "spacer-3": $spacer-3,
  "spacer-4": $spacer-4,
  "spacer-5": $spacer-5,
  "spacer-6": $spacer-6,
  "spacer-7": $spacer-7,
  "spacer-8": $spacer-8,
  "spacer-9": $spacer-9
);

/* Colors */

$white: #ffffff;
$black: #0D0B0B;

$gray-100: #fafbfc;
$gray-200: #e6e9ed;
$gray-300: #c9ced4;
$gray-400: #9ea4ac;
$gray-300-50: rgba($gray-300, .5);
$gray-500: #707780;
$gray-600: #464b52;
$gray-700: #32363b;
$gray-800: #282c2f;
$gray-900: #191c1f;

$brand-secondary-lightest: #e7f8fd;
$brand-secondary-lighter: #60b1c5;
$brand-secondary-light: #155364;
$brand-secondary: #053d4e;
$brand-secondary-dark: #012a36;
$brand-secondary-darker: #00181e;
$brand-secondary-50: rgba($brand-secondary, .5);

$primary-lightest: #efebf9;
$primary-lighter: #cabdf3;
$primary-light: #F3D03E;
$primary: #FCA311;
$primary-dark: #E68C05;
$primary-darker: #4729a8;
$primary-50: rgba($primary, .5);

$secondary-lightest: #ecf0f9;
$secondary-lighter: #bbccf3;
$secondary-light: #8aa4e1;
$secondary: #6282cd;
$secondary-dark: #4265b7;
$secondary-darker: #264ca4;
$secondary-50: rgba($secondary, .5);

$tertiary-lightest: #ecf8f8;
$tertiary-lighter: #b3f0f0;
$tertiary-light: #7cdbdb;
$tertiary: #51c1c3;
$tertiary-dark: #30a8a8;
$tertiary-darker: #127c7c;
$tertiary-50: rgba($tertiary, .5);

$success-lightest: #d7fee8;
$success-lighter: #28e277;
$success-light: #0fc75e;
$success: #03a046;
$success-dark: #03873b;
$success-darker: #026e30;
$success-50: rgba($success, .5);

$danger-lightest: #f8dedd;
$danger-lighter: #df8f8b;
$danger-light: #d46863;
$danger: #d6342c;
$danger-dark: #c32716;
$danger-darker: #a01607;
$danger-50: rgba($danger, .5);

$warning-lightest: #fdf4d9;
$warning-lighter: #f6d66f;
$warning-light: #f3c83f;
$warning: #efba0f;
$warning-dark: #d8a80e;
$warning-darker: #8a6b0a;
$warning-50: rgba($warning, .5);

$info-lightest: #d9ebfc;
$info-lighter: #6ca7e0;
$info-light: #368ee2;
$info: #1174d3;
$info-dark: #0f68bd;
$info-darker: #0d5ba5;
$info-50: rgba($info, .5);

$colors: (
  "white": $white,
  "black": $black,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-300-50": $gray-300-50,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "brand-secondary-lightest": $brand-secondary-lightest,
  "brand-secondary-lighter": $brand-secondary-lighter,
  "brand-secondary-light": $brand-secondary-light,
  "brand-secondary": $brand-secondary,
  "brand-secondary-dark": $brand-secondary-dark,
  "brand-secondary-darker": $brand-secondary-darker,
  "brand-secondary-50": $brand-secondary-50,
  "primary-lightest": $primary-lightest,
  "primary-lighter": $primary-lighter,
  "primary-light": $primary-light,
  "primary": $primary,
  "primary-dark": $primary-dark,
  "primary-darker": $primary-darker,
  "primary-50": $primary-50,
  "secondary-lightest": $secondary-lightest,
  "secondary-lighter": $secondary-lighter,
  "secondary-light": $secondary-light,
  "secondary": $secondary,
  "secondary-dark": $secondary-dark,
  "secondary-darker": $secondary-darker,
  "secondary-50": $secondary-50,
  "tertiary-lightest": $tertiary-lightest,
  "tertiary-lighter": $tertiary-lighter,
  "tertiary-light": $tertiary-light,
  "tertiary": $tertiary,
  "tertiary-dark": $tertiary-dark,
  "tertiary-darker": $tertiary-darker,
  "tertiary-50": $tertiary-50,
  "success-lightest": $success-lightest,
  "success-lighter": $success-lighter,
  "success-light": $success-light,
  "success": $success,
  "success-dark": $success-dark,
  "success-darker": $success-darker,
  "success-50": $success-50,
  "danger-lightest": $danger-lightest,
  "danger-lighter": $danger-lighter,
  "danger-light": $danger-light,
  "danger": $danger,
  "danger-dark": $danger-dark,
  "danger-darker": $danger-darker,
  "danger-50": $danger-50,
  "warning-lightest": $warning-lightest,
  "warning-lighter": $warning-lighter,
  "warning-light": $warning-light,
  "warning": $warning,
  "warning-dark": $warning-dark,
  "warning-darker": $warning-darker,
  "warning-50": $warning-50,
  "info-lightest": $info-lightest,
  "info-lighter": $info-lighter,
  "info-light": $info-light,
  "info": $info,
  "info-dark": $info-dark,
  "info-darker": $info-darker,
  "info-50": $info-50
);

/* Breakpoints */

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1700px;

$breakpoints: (
  "xs": $breakpoint-xs,
  "sm": $breakpoint-sm,
  "md": $breakpoint-md,
  "lg": $breakpoint-lg,
  "xl": $breakpoint-xl,
  "xxl": $breakpoint-xxl
);

/* Border Radius */

$border-radius: $spacer-1;
$border-radius-lg: $spacer-2;
$border-radius-sm: $spacer-1 / 2;
$border-radius-pill: $spacer-8;

$borders: (
  "border-radius": $border-radius,
  "border-radius-lg": $border-radius-lg,
  "border-radius-sm": $border-radius-sm,
  "border-radius-pill": $border-radius-pill
);

/* Shadows */

$box-shadow-none: none;
$box-shadow-sm: 0 2px 1px -1px rgba($gray-500, .2), 0 1px 1px 0 rgba($gray-500, .14), 0 1px 3px 0 rgba($gray-500, .12);
$box-shadow: 0 3px 5px -1px rgba($gray-500, .2), 0 6px 10px 0 rgba($gray-500, .14), 0 1px 18px 0 rgba($gray-500, .12);
$box-shadow-lg: 0 11px 15px -7px rgba($gray-500, .2), 0 24px 38px 3px rgba($gray-500, .14), 0 9px 46px 8px rgba($gray-500, .12);

$shadows: (
  "box-shadow": $box-shadow,
  "box-shadow-lg": $box-shadow-lg,
  "box-shadow-sm": $box-shadow-sm,
  "box-shadow-none": $box-shadow-none
);

/* Typography - Fonts and sizes */

$font-family:
  "Nunito Sans",
  -apple-system,
  blinkmacsystemfont,
  "Segoe UI",
  helvetica,
  arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";

$font-family-monospace:
  sfmono-regular,
  menlo,
  monaco,
  consolas,
  "Liberation Mono",
  "Courier New",
  monospace;

$font-weight-light: 300;
$font-weight: 400;
$font-weight-bold: 700;

$line-height-sm: 1;
$line-height: 1.2;
$line-height-lg: 1.5;

$typography: (
  "font-family": $font-family,
  "font-family-monospace": $font-family-monospace,
  "font-weight-light": $font-weight-light,
  "font-weight": $font-weight,
  "font-weight-bold": $font-weight-bold,
  "line-height": $line-height,
  "line-height-lg": $line-height-lg
);

$text-1: .75rem; // 12px
$text-2: 1rem; // 16px (base)
$text-3: 1.25rem; // 20px
$text-4: 1.5rem; // 24px
$text-5: 1.75rem; // 28px
$text-6: 2rem; // 32px
$text-7: 2.25rem; // 36px
$text-8: 2.5rem; // 40px
$text-9: 4.5rem; // 40px

$text-sizes: (
  "text-1": $text-1,
  "text-2": $text-2,
  "text-3": $text-3,
  "text-4": $text-4,
  "text-5": $text-5,
  "text-6": $text-6,
  "text-7": $text-7,
  "text-8": $text-8,
  "text-9": $text-9
);

/* Easing and Timing */

$easing: cubic-bezier(.2, 1, .2, 1);
$timing: 500ms;

/* Grid */

$grid-column: 8.33333333333%;

/* Component */

$component-border-color: $gray-200;
$component-border-width: .0625rem; // 1px
$component-border-width-lg: .125rem; // 2px
$component-height: 2.625rem; // 42px
$component-height-sm: 1.75rem; // 28px
$component-height-lg: 3rem; // 48px

/* Alert */

$alert-border-width: $component-border-width;
$alert-padding: $spacer-4;
$alert-padding-dismissable: $spacer-4 $spacer-8 $spacer-4 $spacer-4;

/* Avatar */

$avatar-xs: $spacer-4;
$avatar-sm: $spacer-5;
$avatar-md: $spacer-6;
$avatar-lg: $spacer-7;
$avatar-xl: $spacer-8;
$avatar-xxl: $spacer-8 * 2;

/* Badge */

$badge-border-width: $component-border-width;
$badge-padding: $spacer-2 $spacer-3;
$badge-padding-dismissable: $spacer-2 $spacer-6 $spacer-2 $spacer-3;
$badge-padding-sm: $spacer-1 $spacer-2;
$badge-padding-dismissable-sm: $spacer-1 $spacer-5 $spacer-1 $spacer-2;
$badge-padding-lg: $spacer-2 $spacer-4;
$badge-padding-dismissable-lg: $spacer-2 $spacer-6 $spacer-2 $spacer-4;

/* Button */

$button-border-width: $component-border-width;
$button-padding: $spacer-2 $spacer-4;
$button-padding-sm: $spacer-1 $spacer-2;
$button-padding-lg: $spacer-2 $spacer-4;

/* Card */

$card-border-width: $component-border-width;
$card-padding: $spacer-4;

/* Collapse */

$collapse-arrow-margin: $spacer-4;
$collapse-arrow-opacity: 1;
$collapse-padding: $spacer-2 $spacer-4;

/* Dropdown */

$dropdown-border-width: $component-border-width;
$dropdown-menu-padding: $spacer-2 0;
$dropdown-menu-width: $spacer-8 * 4;

/* Form */

$input-border-width: $component-border-width;
$input-padding: $spacer-2 $spacer-4;
$input-padding-sm: $spacer-1 $spacer-2;
$input-padding-lg: $spacer-2 $spacer-4;

/* Modal */

$modal-border-width: $component-border-width;
$modal-padding: $spacer-4;

/* Pagination */

$pagination-border-width: $component-border-width;
$pagination-padding: $spacer-2 $spacer-4;
$pagination-padding-sm: $spacer-1 $spacer-2;
$pagination-padding-lg: $spacer-2 $spacer-4;

/* Popover */

$popover-border-width: $component-border-width;
$popover-padding: $spacer-4;

/* Progress */

$progress-bg: $primary;

/* Sidebar */

$sidebar-border-width: $component-border-width;
$sidebar-padding: $spacer-4;

/* Structure */

$structure-border-width: $component-border-width;
$structure-menu-minimized-width: 70px;
$structure-menu-width: 300px;

/* Table */

$table-border-width: $component-border-width;
$table-padding: $spacer-2 $spacer-4;
$table-padding-sm: $spacer-1 $spacer-2;
$table-padding-lg: $spacer-2 $spacer-4;

/* Tooltip */

$tooltip-border-width: $component-border-width;
$tooltip-padding: $spacer-2;
