@import "functions";

.empty-store-content {
  text-align: center;

  &-title {
    @include fluid-typography(24, 32);
  }

  &-subtitle {
    @include fluid-typography(16, 20);
  }
}

.empty-store-img {
  width: 60%;
  height: 60%;
  margin-top: 32px;
}

.empty-creditcard-content {
  text-align: center;
}

.empty-creditcard-img {
  width: 8rem;
  height: 8rem;
}

.empty-favorite-content {
  text-align: center;
  margin-bottom: 32px;
}

.empty-favorite-img {
  width: max(30%, 256px);
  height: max(30%, 256px);
}

.empty-data-content {
  text-align: center;
  margin-bottom: 32px;
}

.empty-data-img {
  width: 8rem;
  height: 8rem;
}