@import "functions";

.signup_company_content_grid {
  @include mobile() {
    grid-template-columns: 1fr;
    column-gap: 0px;
  }

  @include tablet() {
    grid-template-columns: 1fr;
    column-gap: 0px;
  }
}

.signup_content_image {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include mobile() {
    display: none;
  }

  @include tablet() {
    display: none;
  }
}

.signup_image {
  width: 512px;
  height: 412px;
}