@import "variables";

.molecule-menu-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  color: $white;
  cursor: pointer;

  &__selected {
    background-color: #2A2D2E; 
    border-left: 4px solid $primary;  
  }
}

.molecule-menu-content_arrow {
  display: flex;
  align-items: center;
}

.molecule-menu-content_arrow_icon {
  color: #FFFFFF;

  &__selected {
    color: $primary;
  }
}