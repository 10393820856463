@import "variables";

.atom-button {
  outline: 0;
  cursor: pointer;
  border: none;
  font-size: $text-2;
  border-radius: $spacer-2;
  padding-left: $spacer-4;
  padding-right: $spacer-4;
  padding-top: $spacer-2;
  padding-bottom: $spacer-2;

  &-primary {
    background: $primary;
    border: 2px solid $primary-dark;
    color: $white;
    font-weight: 600;
    &:active { 
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.24); 
      background-color: $primary;
      opacity: 0.9;
    }
    &:hover {
      background-color: $primary-dark;
    }
  }

  &-secondary {
    background: $secondary;
    border: 2px solid $secondary;
    color: $white;
    font-weight: 600;
    &:active { 
      transform: scale(0.98); 
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.24); 
      background-color: $secondary;
      opacity: 0.8;
    } 
  }

  &-gray {
    background: $gray-500;
    border: 2px solid $gray-500;
    color: $white;
    font-weight: 600;
  }

  &-outline-black {
    background: $white;
    border: 2px solid $black;;
    color: $black;
    font-weight: 600;

    &:active { 
      transform: scale(0.98); 
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.24); 
      background-color: $gray-100;
      opacity: 0.8;
    } 
  }

  &-outline-primary {
    background: $white;
    border: 2px solid $primary;
    color: $black;
    font-weight: 600;
    
    &:active { 
      transform: scale(0.98); 
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.24); 
      background-color: $gray-100;
      opacity: 0.8;
    } 
  }

  &-excel {
    background: #1D6F42;
    border: 2px solid #1D6F42;
    color: $white;
    font-weight: 600;
  }

  &-danger {
    background: #d46863;
    border: 2px solid #d46863;
    color: $white;
    font-weight: 600;
  }

  &-block {
    width: 100%;
  }

  &-disabled {
    background: $gray-300-50;
    border: 2px solid $gray-300-50;
    color: $black;
    font-weight: 600;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &-loading {
    text-align: center;
    opacity: 0.5;
  }
}