@import "variables";

.content_menu_company {
  background: #1F2122;
  color: $white;
}

.molecule-menu-closecar-logo {
  width: 100px;
}

.molecule-menu-company-logo {
  width: 150px;
  display: block;
  margin: 0 auto;
}