@import "variables";

.page-company-loading-content {
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;

  &__menu {
    background: #1F2122;
  }

  &__logo {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
}