@mixin fluid-typography-base($minFont,$maxFont,$minBreakpoint,$maxBreakpoint) {
  
  $maxLessOne: $maxBreakpoint - 1;
  
  $avg: ($maxFont + $minFont) / 2;
 
  font-size: #{$minFont}px;
  
  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {
    
    font-size: #{$avg}px;
      
    font-size: calc(#{$minFont}px + (#{$maxFont} - #{$minFont}) * (100vw - #{$minBreakpoint}px) / (#{$maxBreakpoint} - #{$minBreakpoint})) !important
  }
  
  @media (min-width: #{$maxBreakpoint}px) {
    font-size: #{$maxFont}px;
  }
}

@mixin fluid-typography($min, $max) {
  @include fluid-typography-base($min, $max, 320, 1824);
}

@mixin mobile() {
  @media (min-width: 320px) and (max-width: 480px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

@mixin laptop() {
  @media (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: 1025px){
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == mobile) {
      @include mobile {
        @content
      }
    } @else if ($key == tablet) {
      @include tablet {
        @content
      }
    } @else if ($key == laptop) {
      @include laptop {
        @content
      }
    } @else if ($key == desktop) {
      @include desktop {
        @content
      }
    }
  }
}