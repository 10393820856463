@import "variables";

.atom-layout-base-body {
  display: grid;
  grid-area: main;
}

.atom-layout-base-body-without-header {
  display: grid;
  grid-area: main;
}