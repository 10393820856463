@import "functions";
@import "variables";

.take-photo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed $gray-400;
  cursor: pointer;
  border-radius: 8px;
  width: 72px;
  height: 72px;

  &__icon {
    font-size: 24px;
    color: $gray-400;
    font-weight: bold;
  }

  &__input-file {
    display: none !important;
  }
}

.take-photo-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}