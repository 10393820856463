@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind Customs */
.mobile-visibility {
  @apply sm:hidden block;
  /* Other custom styles */
}

@font-face {
  font-family: 'MuseoSans';
  src: local('Gilroy-Regular'), url(./fonts/MuseoSans_300.otf) format('truetype');
}

@font-face {
  font-family: 'MuseoSans-500';
  src: local('MuseoSans-500'), url(./fonts/MuseoSans_500.otf) format('truetype');
}

@font-face {
  font-family: 'MuseoSans-700';
  src: local('MuseoSans-700'), url(./fonts/MuseoSans_700.otf) format('truetype');
}

@font-face {
  font-family: 'MuseoSans-900';
  src: local('MuseoSans-900'), url(./fonts/MuseoSans_900.otf) format('truetype');
}

* {
  font-family: 'MuseoSans';
  letter-spacing: 1px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  width: 100%;
  height: 100%;
  scroll-behavior:smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/** Divider **/

._divider_right {
  border-right-width: 1px;
  border-right-color: #f0f0f0;
  border-right-style: solid;
}

._cursor_pointer {
  cursor: pointer;
}

._display-none {
  display: none;
}

._button_default_action {
  background: #008489 !important;
  border-color: #008489 !important;
}

._grid-search-content {
  display: grid;
  grid-template-columns: 320px 1fr;
  width: 1280px;
  grid-gap: 16px;
}

/** Backgrounf **/

._background_grey_light {
  background-color: #f0f0f0 !important;
}

/** Spacing **/

._m-0 {
  margin: 0 !important;
}

._mt-0,
._my-0 {
  margin-top: 0 !important;
}

._mr-0,
._mx-0 {
  margin-right: 0 !important;
}

._mb-0,
._my-0 {
  margin-bottom: 0 !important;
}

._ml-0,
._mx-0 {
  margin-left: 0 !important;
}

._m-1 {
  margin: 4px !important;
  margin: 4px !important;
}

._mt-1,
._my-1 {
  margin-top: 4px !important;
  margin-top: 4px !important;
}

._mr-1,
._mx-1 {
  margin-right: 4px !important;
  margin-right: 4px !important;
}

._mb-1,
._my-1 {
  margin-bottom: 4px !important;
  margin-bottom: 4px !important;
}

._ml-1,
._mx-1 {
  margin-left: 4px !important;
  margin-left: 4px !important;
}

._m-2 {
  margin: 8px !important;
  margin: 8px !important;
}

._mt-2,
._my-2 {
  margin-top: 8px !important;
  margin-top: 8px !important;
}

._mr-2,
._mx-2 {
  margin-right: 8px !important;
  margin-right: 8px !important;
}

._mb-2,
._my-2 {
  margin-bottom: 8px !important;
  margin-bottom: 8px !important;
}

._ml-2,
._mx-2 {
  margin-left: 8px !important;
  margin-left: 8px !important;
}

._m-3 {
  margin: 12px !important;
  margin: 12px !important;
}

._mt-3,
._my-3 {
  margin-top: 12px !important;
  margin-top: 12px !important;
}

._mr-3,
._mx-3 {
  margin-right: 12px !important;
  margin-right: 12px !important;
}

._mb-3,
._my-3 {
  margin-bottom: 12px !important;
  margin-bottom: 12px !important;
}

._ml-3,
._mx-3 {
  margin-left: 12px !important;
  margin-left: 12px !important;
}

._m-4 {
  margin: 16px !important;
  margin: 16px !important;
}

._mt-4,
._my-4 {
  margin-top: 16px !important;
  margin-top: 16px !important;
}

._mr-4,
._mx-4 {
  margin-right: 16px !important;
  margin-right: 16px !important;
}

._mb-4,
._my-4 {
  margin-bottom: 16px !important;
  margin-bottom: 16px !important;
}

._ml-4,
._mx-4 {
  margin-left: 16px !important;
  margin-left: 16px !important;
}

._m-5 {
  margin: 24px !important;
  margin: 24px !important;
}

._mt-5,
._my-5 {
  margin-top: 24px !important;
  margin-top: 24px !important;
}

._mr-5,
._mx-5 {
  margin-right: 24px !important;
  margin-right: 24px !important;
}

._mb-5,
._my-5 {
  margin-bottom: 24px !important;
  margin-bottom: 24px !important;
}

._ml-5,
._mx-5 {
  margin-left: 24px !important;
  margin-left: 24px !important;
}

._m-6 {
  margin: 32px !important;
  margin: 32px !important;
}

._mt-6,
._my-6 {
  margin-top: 32px !important;
  margin-top: 32px !important;
}

._mr-6,
._mx-6 {
  margin-right: 32px !important;
  margin-right: 32px !important;
}

._mb-6,
._my-6 {
  margin-bottom: 32px !important;
  margin-bottom: 32px !important;
}

._ml-6,
._mx-6 {
  margin-left: 32px !important;
  margin-left: 32px !important;
}

._m-7 {
  margin: 40px !important;
  margin: 40px !important;
}

._mt-7,
._my-7 {
  margin-top: 40px !important;
  margin-top: 40px !important;
}

._mr-7,
._mx-7 {
  margin-right: 40px !important;
  margin-right: 40px !important;
}

._mb-7,
._my-7 {
  margin-bottom: 40px !important;
  margin-bottom: 40px !important;
}

._ml-7,
._mx-7 {
  margin-left: 40px !important;
  margin-left: 40px !important;
}

._m-8 {
  margin: 48px !important;
  margin: 48px !important;
}

._mt-8,
._my-8 {
  margin-top: 48px !important;
  margin-top: 48px !important;
}

._mr-8,
._mx-8 {
  margin-right: 48px !important;
  margin-right: 48px !important;
}

._mb-8,
._my-8 {
  margin-bottom: 48px !important;
  margin-bottom: 48px !important;
}

._ml-8,
._mx-8 {
  margin-left: 48px !important;
  margin-left: 48px !important;
}

._p-0 {
  padding: 0 !important;
}

._pt-0,
._py-0 {
  padding-top: 0 !important;
}

._pr-0,
._px-0 {
  padding-right: 0 !important;
}

._pb-0,
._py-0 {
  padding-bottom: 0 !important;
}

._pl-0,
._px-0 {
  padding-left: 0 !important;
}

._p-1 {
  padding: 4px !important;
  padding: 4px !important;
}

._pt-1,
._py-1 {
  padding-top: 4px !important;
  padding-top: 4px !important;
}

._pr-1,
._px-1 {
  padding-right: 4px !important;
  padding-right: 4px !important;
}

._pb-1,
._py-1 {
  padding-bottom: 4px !important;
  padding-bottom: 4px !important;
}

._pl-1,
._px-1 {
  padding-left: 4px !important;
  padding-left: 4px !important;
}

._p-2 {
  padding: 8px !important;
  padding: 8px !important;
}

._pt-2,
._py-2 {
  padding-top: 8px !important;
  padding-top: 8px !important;
}

._pr-2,
._px-2 {
  padding-right: 8px !important;
  padding-right: 8px !important;
}

._pb-2,
._py-2 {
  padding-bottom: 8px !important;
  padding-bottom: 8px !important;
}

._pl-2,
._px-2 {
  padding-left: 8px !important;
  padding-left: 8px !important;
}

._p-3 {
  padding: 12px !important;
  padding: 12px !important;
}

._pt-3,
._py-3 {
  padding-top: 12px !important;
  padding-top: 12px !important;
}

._pr-3,
._px-3 {
  padding-right: 12px !important;
  padding-right: 12px !important;
}

._pb-3,
._py-3 {
  padding-bottom: 12px !important;
  padding-bottom: 12px !important;
}

._pl-3,
._px-3 {
  padding-left: 12px !important;
  padding-left: 12px !important;
}

._p-4 {
  padding: 16px !important;
  padding: 16px !important;
}

._pt-4,
._py-4 {
  padding-top: 16px !important;
  padding-top: 16px !important;
}

._pr-4,
._px-4 {
  padding-right: 16px !important;
  padding-right: 16px !important;
}

._pb-4,
._py-4 {
  padding-bottom: 16px !important;
  padding-bottom: 16px !important;
}

._pl-4,
._px-4 {
  padding-left: 16px !important;
  padding-left: 16px !important;
}

._p-5 {
  padding: 24px !important;
  padding: 24px !important;
}

._pt-5,
._py-5 {
  padding-top: 24px !important;
  padding-top: 24px !important;
}

._pr-5,
._px-5 {
  padding-right: 24px !important;
  padding-right: 24px !important;
}

._pb-5,
._py-5 {
  padding-bottom: 24px !important;
  padding-bottom: 24px !important;
}

._pl-5,
._px-5 {
  padding-left: 24px !important;
  padding-left: 24px !important;
}

._p-6 {
  padding: 32px !important;
  padding: 32px !important;
}

._pt-6,
._py-6 {
  padding-top: 32px !important;
  padding-top: 32px !important;
}

._pr-6,
._px-6 {
  padding-right: 32px !important;
  padding-right: 32px !important;
}

._pb-6,
._py-6 {
  padding-bottom: 32px !important;
  padding-bottom: 32px !important;
}

._pl-6,
._px-6 {
  padding-left: 32px !important;
  padding-left: 32px !important;
}

._p-7 {
  padding: 40px !important;
  padding: 40px !important;
}

._pt-7,
._py-7 {
  padding-top: 40px !important;
  padding-top: 40px !important;
}

._pr-7,
._px-7 {
  padding-right: 40px !important;
  padding-right: 40px !important;
}

._pb-7,
._py-7 {
  padding-bottom: 40px !important;
  padding-bottom: 40px !important;
}

._pl-7,
._px-7 {
  padding-left: 40px !important;
  padding-left: 40px !important;
}

._p-8 {
  padding: 48px !important;
  padding: 48px !important;
}

._pt-8,
._py-8 {
  padding-top: 48px !important;
  padding-top: 48px !important;
}

._pr-8,
._px-8 {
  padding-right: 48px !important;
  padding-right: 48px !important;
}

._pb-8,
._py-8 {
  padding-bottom: 48px !important;
  padding-bottom: 48px !important;
}

._pl-8,
._px-8 {
  padding-left: 48px !important;
  padding-left: 48px !important;
}

._m-auto {
  margin: auto !important;
}

._mt-auto,
._my-auto {
  margin-top: auto !important;
}

._mr-auto,
._mx-auto {
  margin-right: auto !important;
}

._mb-auto,
._my-auto {
  margin-bottom: auto !important;
}

._ml-auto,
._mx-auto {
  margin-left: auto !important;
}

/** Text **/

._line-height-sm {
  line-height: 1 !important;
  line-height: 1 !important;
}

._line-height {
  line-height: 1.2 !important;
  line-height: 1.2 !important;
}

._line-height-lg {
  line-height: 1.5 !important;
  line-height: 1.5 !important;
}

._text-1 {
  font-size: .75rem !important; /** 12px **/
  font-size: .75rem !important; /** 12px **/
}

._text-2 {
  font-size: 1rem !important; /** 16px (base) **/
  font-size: 1rem !important; /** 16px (base) **/
}

._text-3 {
  font-size: 1.25rem !important; /** 20px **/
  font-size: 1.25rem !important; /** 20px **/
}

._text-4 {
  font-size: 1.5rem !important; /** 24px **/
  font-size: 1.5rem !important; /** 24px **/
}

._text-5 {
  font-size: 1.75rem !important; /** 28px **/
  font-size: 1.75rem !important; /** 28px **/
}

._text-6 {
  font-size: 2rem !important; /** 32px **/
  font-size: 2rem !important; /** 32px **/
}

._text-7 {
  font-size: 2.25rem !important; /** 36px **/
  font-size: 2.25rem !important; /** 36px **/
}

._text-8 {
  font-size: 2.5rem !important; /** 40px **/
  font-size: 2.5rem !important; /** 40px **/
}

._text-9 {
  font-size: 2.75rem !important; /** 40px **/
  font-size: 2.75rem !important; /** 40px **/
}

._text-10 {
  font-size: 3.00rem !important; /** 40px **/
  font-size: 3.00rem !important; /** 40px **/
}

._text-monospace {
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace !important;
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace !important;
}

._text-left {
  text-align: left !important;
}

._text-right {
  text-align: right !important;
}

._text-center {
  text-align: center !important;
}

._text-justify {
  text-align: justify !important;
}

._text-wrap {
  white-space: normal !important;
}

._text-nowrap {
  white-space: nowrap !important;
}

._text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._text-lowercase {
  text-transform: lowercase !important;
}

._text-uppercase {
  text-transform: uppercase !important;
}

._text-capitalize {
  text-transform: capitalize !important;
}

._text-color-light-grey {
  color: #9ea4ac !important;
}

._text-color-grey {
  color: #5C6672 !important;
}

._text-color-green {
  color: #43AA8B !important;
}

._text-color-red {
  color: #FF1053 !important;
}

._text-color-white {
  color: #FFFFFF !important;
}

._text-color-black {
  color: #000000 !important;
}

._text-color-action {
  color: #008489 !important;
}

._text-color-primary {
  color: #F2A900 !important;
}

._font-weight-light {
  font-weight: 300 !important;
  font-weight: 300 !important;
}

._font-weight {
  font-weight: 400 !important;
  font-weight: 400 !important;
}

._font-weight-bold {
  font-weight: 700 !important;
  font-weight: 700 !important;
}

._font-italic {
  font-style: italic !important;
}

._text-decoration-none {
  text-decoration: none !important;
}

._text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

/** Grid **/

._grid-columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

._grid-columns-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
}

._grid-columns-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

/** Flex **/

._flex-diplay {
  display: flex !important;
}

._flex-row {
  flex-direction: row !important;
}

._flex-column {
  flex-direction: column !important;
}

._flex-row-reverse {
  flex-direction: row-reverse !important;
}

._flex-column-reverse {
  flex-direction: column-reverse !important;
}

._flex-wrap {
  flex-wrap: wrap !important;
}

._flex-nowrap {
  flex-wrap: nowrap !important;
}

._flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

._flex-fill {
  flex: 1 1 auto !important;
}

._flex-grow-0 {
  flex-grow: 0 !important;
}

._flex-grow-1 {
  flex-grow: 1 !important;
}

._flex-shrink-0 {
  flex-shrink: 0 !important;
}

._flex-shrink-1 {
  flex-shrink: 1 !important;
}

._flex-gap-0 {
  gap: 8px !important;
}

._flex-gap-1 {
  gap: 16px !important;
}

._flex-gap-2 {
  gap: 24px !important;
}

._justify-content-start {
  justify-content: flex-start !important;
}

._justify-content-end {
  justify-content: end !important;
}

._justify-content-center {
  justify-content: center !important;
}

._justify-content-between {
  justify-content: space-between !important;
}

._justify-content-around {
  justify-content: space-around !important;
}

._align-items-start {
  align-items: flex-start !important;
}

._align-items-end {
  align-items: flex-end !important;
}

._align-items-center {
  align-items: center !important;
}

._align-items-baseline {
  align-items: baseline !important;
}

._align-items-stretch {
  align-items: stretch !important;
}

._align-content-start {
  align-content: flex-start !important;
}

._align-content-end {
  align-content: end !important;
}

._align-content-center {
  align-content: center !important;
}

._align-content-between {
  align-content: space-between !important;
}

._align-content-around {
  align-content: space-around !important;
}

._align-content-stretch {
  align-content: stretch !important;
}

._align-self-auto {
  align-self: auto !important;
}

._align-self-start {
  align-self: flex-start !important;
}

._align-self-end {
  align-self: flex-end !important;
}

._align-self-center {
  align-self: center !important;
}

._align-self-baseline {
  align-self: baseline !important;
}

._align-self-stretch {
  align-self: stretch !important;
}

/** Ant **/
/* .ant-steps-icon:has(span) {
  top: -3.5px !important;
} */

.ant-collapse-arrow {
  vertical-align: 1px !important;
}