@import "functions";

.layout_container {
  padding: 0 24px;
  margin: 0 auto;

  @include media(desktop, laptop) {
    box-sizing: content-box;
    max-width: 1280px;
    min-width: 1280px;
  }
}