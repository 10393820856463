@import "variables";

.organism-admin-content {
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;

  &__menu {
    background: #1F2122;
  }

  &__body {
    background: $gray-100;
  }
}