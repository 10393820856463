@import "functions";

.change_password_content_grid {
  @include media(mobile, tablet) {
    grid-template-columns: 1fr;
    column-gap: 0px;
  }
}

.change_password_content_image {
  text-align: center;

  @include media(mobile, tablet) {
    display: none;
  }
}

.change_password_image {
  width: 512px;
}