@import "variables";

.atom-layout-base {
  min-height: 100%;
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}

.atom-layout-base-without-header {
  display: grid;
  grid-template-areas:
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}