@import "functions";

.creditcard {

  &-title {
    @include fluid-typography(21, 36);
  }

  &-subtitle {
    @include fluid-typography(14, 16);
  }

  &-content {
      border-top: 1px solid #F0F0F0;
      cursor: pointer;

      &-selected {
        border: 2px solid #A7E8BD;
        border-radius: 4px;
      }

      &:hover {
        background-color:#F0F0F0;
      }
  }

  &-content-data {
    display: grid;
    grid-template-columns: 72px 1fr;
    padding-left: 16px;
  }

  &-seleted-label {
    background-color: #A7E8BD;
    width: 100%;
  }

  &-input-data {
    padding-left: 16px;
    padding-right: 16px;

    &-grid {
      @include media(mobile, tablet) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
      }
    }

    &-form {
      margin-bottom: 16px;
    }
  }

  &-input-cvv {
    width: 10rem;
  }

  &-input-cpf {
    width: 15rem;
  }

  &-image {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }

  &-input-full {
    width: 100%;
  }
  
  &-select-brand {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 2rem;

    @include media(mobile, tablet) {
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-select-brand-box {
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color:#F0F0F0;
    }

    &-selected {
      border: 2px solid #A7E8BD;
      border-radius: 4px;
      background-color:#F0F0F0;
    }
  }

  &-first-part {
    @include media(mobile, tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }

  &-second-part {
    @include media(mobile, tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
}