.select-disabled-for-mobile > .ant-select-selector {
  color: rgba(0, 0, 0, 0.85) !important;
  background-color: #FFFFFF !important;
  @apply !rounded-md;
  @apply !text-base;
  @apply !text-gray-900;
  @apply !outline;
  @apply !outline-1;
  @apply !-outline-offset-1;
  @apply !outline-gray-300;
  @apply placeholder:!text-gray-400;
}

.select-for-web > .ant-select-selector {
  @apply !rounded-md;
  @apply !text-base;
  @apply !text-gray-900;
  @apply !outline;
  @apply !outline-1;
  @apply !-outline-offset-1;
  @apply !outline-gray-300;
  @apply placeholder:!text-gray-400;
}